import { EmptyState } from "src/components/EmptyState"
import { ParadiseDetailsLayout } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseProfileOverview } from "src/components/Paradise/ParadiseProfiles/ParadiseProfileDetails/ParadiseProfileOverview/ParadiseProfileOverview"
import { DetailsHeading2 } from "src/components/Paradise/ParadiseSharedStyles"
import { LoadingWrapper } from "src/components/Paradise/sharedStyles"
import { useFetchProfile } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import Svg404 from "src/ui/icons/404.svg"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"

export function ParadiseProfileDetails({ profileId }: { profileId: string }) {
  const { t } = useTranslate()

  const fetchProfile = useFetchProfile({
    id: profileId,
  })

  if (fetchProfile.isError) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  if (fetchProfile.isLoading) {
    return (
      <LoadingWrapper>
        <MSkeleton height={40} width="30ch" />
        <MSkeleton height={20} width="40ch" />
      </LoadingWrapper>
    )
  }

  const profile = fetchProfile.data

  return (
    <ParadiseDetailsLayout
      meta={<DetailsHeading2>{profile.name}</DetailsHeading2>}
      left={<ParadiseProfileOverview profile={profile} />}
      right={<></>}
      breadcrumbs={[
        {
          to: Routes.ParadiseProfiles.location(),
          label: "Profiles",
        },
        {
          to: Routes.ParadiseProfile.location(profile.id),
          label: profile.id,
        },
      ]}
      breadcrumbCopyValue={profile.id}
    />
  )
}
