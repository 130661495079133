import { ParadiseOverviewExpandable } from "src/components/Paradise/ParadiseOverviewExpandable"
import { ParadiseSettingNumber } from "src/components/Paradise/ParadiseSettings/ParadiseSettingNumber"
import { ParadiseSettingStatic } from "src/components/Paradise/ParadiseSettings/ParadiseSettingStatic"
import { ParadiseSettingToggle } from "src/components/Paradise/ParadiseSettings/ParadiseSettingToggle"
import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { usePatchProfile } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import {
  IProfileRequest,
  IProfileResponse,
} from "src/data/homeProfiles/types/homeProfilesTypes"
import { Routes } from "src/router/routes"
import { InternalLink } from "src/ui/Link/InternalLink"
import { formatDate } from "src/utils/l10n"

export function ParadiseProfileOverview({
  profile,
}: {
  profile: IProfileResponse
}) {
  const patchProfile = usePatchProfile()

  async function handleSave(
    body: IProfileRequest
  ): TSettingContainerOnSaveReturnType {
    try {
      await patchProfile.mutateAsync({
        id: profile.id,
        data: body,
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  return (
    <ParadiseOverviewExpandable rawJson={profile}>
      <ParadiseSettingStatic
        title="Created at"
        value={formatDate({ date: profile.created_at, timezone: "utc" })}
      />
      <ParadiseSettingStatic
        title="Last modified"
        value={formatDate({ date: profile.last_modified, timezone: "utc" })}
      />
      <ParadiseSettingStatic
        title="Organization id"
        value={
          <InternalLink
            to={Routes.ParadiseOrganization.location(profile.organization_id)}
          >
            {profile.organization_id}
          </InternalLink>
        }
      />
      <ParadiseSettingToggle
        title="First notice sms enabled"
        value={
          profile.disturbance_monitoring?.first_notice_sms_enabled ?? false
        }
        onSave={(value) =>
          handleSave({
            disturbance_monitoring: {
              first_notice_sms_enabled: !!value,
            },
          })
        }
      />
      <ParadiseSettingToggle
        title="Second notice sms enabled"
        value={
          profile.disturbance_monitoring?.second_notice_sms_enabled ?? false
        }
        onSave={(value) =>
          handleSave({
            disturbance_monitoring: {
              second_notice_sms_enabled: !!value,
            },
          })
        }
      />
      <ParadiseSettingToggle
        title="Third notice sms enabled"
        value={
          profile.disturbance_monitoring?.third_notice_sms_enabled ?? false
        }
        onSave={(value) =>
          handleSave({
            disturbance_monitoring: {
              third_notice_sms_enabled: !!value,
            },
          })
        }
      />
      <ParadiseSettingToggle
        title="First notice flash and sound"
        value={
          profile.disturbance_monitoring?.first_notice_flash_and_sound ?? false
        }
        onSave={(value) =>
          handleSave({
            disturbance_monitoring: {
              first_notice_flash_and_sound: !!value,
            },
          })
        }
      />
      <ParadiseSettingToggle
        title="Second notice flash and sound"
        value={
          profile.disturbance_monitoring?.second_notice_flash_and_sound ?? false
        }
        onSave={(value) =>
          handleSave({
            disturbance_monitoring: {
              second_notice_flash_and_sound: !!value,
            },
          })
        }
      />
      <ParadiseSettingToggle
        title="Thirs notice flash and sound"
        value={
          profile.disturbance_monitoring?.third_notice_flash_and_sound ?? false
        }
        onSave={(value) =>
          handleSave({
            disturbance_monitoring: {
              third_notice_flash_and_sound: !!value,
            },
          })
        }
      />
      <ParadiseSettingToggle
        title="First notice autocall enabled"
        value={
          profile.disturbance_monitoring?.first_notice_autocall_enabled ?? false
        }
        onSave={(value) =>
          handleSave({
            disturbance_monitoring: {
              first_notice_autocall_enabled: !!value,
            },
          })
        }
      />
      <ParadiseSettingToggle
        title="Second notice autocall enabled"
        value={
          profile.disturbance_monitoring?.second_notice_autocall_enabled ??
          false
        }
        onSave={(value) =>
          handleSave({
            disturbance_monitoring: {
              second_notice_autocall_enabled: !!value,
            },
          })
        }
      />
      <ParadiseSettingToggle
        title="Third notice autocall enabled"
        value={
          profile.disturbance_monitoring?.third_notice_autocall_enabled ?? false
        }
        onSave={(value) =>
          handleSave({
            disturbance_monitoring: {
              third_notice_autocall_enabled: !!value,
            },
          })
        }
      />
      <ParadiseSettingToggle
        title="Instantly turn on alarm"
        value={profile.security_alarm?.instantly_turn_on ?? false}
        onSave={(value) =>
          handleSave({
            security_alarm: {
              instantly_turn_on: !!value,
            },
          })
        }
      />
      <ParadiseSettingNumber
        title="Grace period security alarm (s)"
        value={profile.security_alarm?.grace_period ?? NaN}
        min={0}
        max={60}
        errorHelperText={() => ""}
        onSave={(value) => {
          return handleSave({
            security_alarm: {
              grace_period: value,
            },
          })
        }}
      />
    </ParadiseOverviewExpandable>
  )
}
